/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!("IntersectionObserver" in window)) {
    import("intersection-observer");
    console.log("# IntersectionObserver is polyfilled!");
  }
};

// Shortcut to global styles
const style = document.documentElement.style;

// Keep track of various heights
const sizeListener = () =>
  style.setProperty("--window-height", window.innerHeight + "px");
sizeListener();
window.addEventListener("resize", sizeListener);
